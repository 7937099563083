.link {
  max-width: 135px;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: solid 1px #949494;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  text-decoration: none !important;

  @media (max-width: 540px)  {
    max-width: 260px !important;
    width: 100%;
  }

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  }

  &__icon {
    display: flex;
    padding-right: 5px;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    color: #1a1a1a;
  }

  &:hover {
    span {
      color: var(--primary-color);
    }
    svg {
      fill: var(--primary-color);
      color: var(--primary-color);
    }
  }

}