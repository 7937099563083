@import '../../../../styles/palette.scss';

.organizations-list {
  width: calc(100% - 240px);
  padding: 0 0 30px 30px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 30px 30px;
  }

  .result-wrapper {
    font-family: "Montserrat";
    font-size: 15px;
    padding: 30px 0;

    pre {
      display: inline;
      font-family: "Montserrat";
      font-size: 20px;
      font-weight: 500;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  .organization {
    margin-top: 30px;

    h2 {
      margin: 0 0 15px 0;
      font-size: 30px;
      font-weight: normal;
      line-height: 1.2;
      word-break: break-word;
    }
  }
}