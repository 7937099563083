footer {

  .powered-by {
    .powered-by-text {
      margin-right: 10px;
      font-family: 'RobotoFlex';
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: right;
      color: #1a1a1a;
    }

    img {
      max-width: 170px;
      max-height: 24px;
      width: 100%;
      &.oe-footer-logo-image {
        width: auto;
        max-height: 1.6rem;
      }
    }
  }
}
