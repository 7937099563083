.add-to-calendar-btn--wrapper {
  margin-bottom: 22px;
}

.add-to-calendar-btn {
  min-width: 137px;
  padding: 5px 10px;
  box-sizing: content-box;

  &:hover:after {
    opacity: 0!important;
  }
  &:disabled {
    &:hover {
      opacity: 0.6;
    }
  }
}
