.editable-agenda--block {
  padding-bottom: 12px;
  padding-top: 12px;
  z-index: 2;
}

.editable-agenda--block-attendees {
  padding-bottom: 10px;
}

.editable-agenda--block-button {
  padding: 10px 30px;
}

.button-block {
  display: flex;
  align-items: flex-end;
  .select-box--container  {
    margin-right: 15px;
    svg {
     cursor: pointer;
    }
  }
  @media (max-width: 640px) { 
    flex-direction: column-reverse;
    row-gap: 20px;
  }
}

.bullet {
  width: 10px;
  margin-right: 10px;
}
