.editable-session--table-row-left-date {
  font-weight: 500;
}

.grid-timing-block {

  &__time {
    line-height: 1.4;
    letter-spacing: 1.5px;
  }

}
