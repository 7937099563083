@import '../../../styles/palette.scss';

section.sessions-search-result {
  margin: 30px 20px;
  width: auto;
  min-height: auto;
  overflow-y: auto;
  font-family: "Montserrat";
  font-size: 15px;

  pre {
    display: inline;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 500;
  }
}

.search-wrapper {

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
}

.grid-days-wrapper {
  .date-filter {

    &__option {
      background-color: $white-gray;
      padding: 5px 10px;

      &--active {
        border: 1px solid $border-gray;
        background-color: $white;

        &:hover {
          background-color: $white-gray;
        }
      }
    }
  }
}

.tracks {
  width: 64px;
  color: #1a1a1a;
}