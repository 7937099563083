@import '../../../../styles/palette.scss';

.cookie-notification {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba($black, 0.8);
  color: $white;

  & > div {
    margin-right: 20px;

    * {
      white-space: pre-wrap;
    }

    a {
      text-decoration: underline;
      color: $green-blue;
    }
  }

  &__button {
    height: 30px;
    width: 60px;
  }
}