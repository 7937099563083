.checkbox-option {
  margin-left: auto;
  border: 1px solid #252c37;
  min-width: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    height: 14px;
    width: 14px;
    fill: white;
  }

  &--selected {
    background-color: var(--primary-color);
    border: none;

    &:hover:not(.checkbox-option--disabled) {
      opacity: 0.7;
    }
  }

  &--disabled:not(.checkbox-option--selected) {
    border: 1px solid #ddd;

    &:hover {
      cursor: not-allowed;
    }

    svg {
      display: none;
    }
  }

  &:hover:not(.checkbox-option--selected):not(.checkbox-option--disabled) {
    background-color: #b2bfc5;

    svg {
      display: none;
    }
  }
}
