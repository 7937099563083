@import '../../styles/palette.scss';

.search-wrapper {
  position: relative;

  display: flex;
  align-items: center;
  background-color: $white;
  cursor: text;
  max-width: 200px;

  .search-input {
    width: 100%;

    height: 40px;
    font-size: 13px;
    font-weight: 500;
    padding: 11px 13px;
    max-width: 100%;
    font-family: "Montserrat";
    font-size: 13px;

    &--with-button {
      padding-right: 34px;
    }
  }

  .reset-button {
    outline: none;
    position: absolute;
    right: 20px;
    color: rgba($off-black, 0.6);
    transition: opacity 0.1s;

    &:hover, &:focus {
      opacity: 0.6;
    }
  }

}