@import '../../styles/palette';

.common-modal {
  width: 342px;

  .shadow-gray {
    border-color: $gray;
  }

  .modal-header  h1 {
    font-size: 2.5rem;
  }
}
