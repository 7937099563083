@import '../../../styles/palette.scss';

.connection-item {
  display: flex;
  flex-direction: column;

  &__show-dial-button {
    background-color: $white;
    border-radius: 6px;
    font-size: 14px;
    width: 150px;
    padding: 6px 12px;
    word-break: break-word;
  }

  &__show-dial-button:hover {
    color: var(--primary-color);
  }
}