@import '../../../styles/palette.scss';

#sign-in-page {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-content-wrapper  {
  padding-bottom: 0;
}

.meeting-details-wrapper {
  padding: 0;
  overflow: initial;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom;
  height: auto;
  min-height: 0;
  margin-bottom: 55px;
}

.meeting-link {
  background-color: $white;

  &--wrapper {
    position: relative;
    max-width: 1536px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;

    > div {
      width: 48%;
    }

    .meeting-details {
      position: absolute;
      right: 24px;
      padding-bottom: 50px;
      background-color: transparent;

      &-content-wrapper {
        margin-top: 20px;
      }
    }

    .time-date-wrapper {
      margin: 20px 0 30px;
    }

    .public-description > p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__connection-details {
    width: 100%;
    left: 0;
  }
}

@media (max-width: 1082px) {
  .event-details > div p {
    text-align: left !important;
  }
}

@media (max-width: 1024px) {
  .meeting-link {
    &--wrapper {
      display: block;
      width: 100%;
      margin: 0 auto;
      background-color: transparent;

      .time-date-wrapper {
        margin: 12px 0 18px;
      }

      .event-details {
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        p {
          text-align: left;
          margin-bottom: 12px;
        }
      }

      .meeting-details {
        position: static;
        padding-bottom: 0;
        width: 100%;

        &-content-wrapper {
          margin-top: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .meeting-details-wrapper {
    padding: 0;
  }
}

@media (max-width: 540px) {
  .meeting-details {
    padding-bottom: 12px;

    .children {
      margin-bottom: 0;
    }
  }
}
