@import '../../styles/palette';

.phone-select {

  &__wrapper {
    position: relative;
    width: 100%;

    span {
      word-break: break-all;
    }
  }

  &__selected-number-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    fill: $gray-darker;

    a {
      display: flex;
      word-break: break-all;
    }
  }

  &__selected-number-wrap {
    @extend .common-selected-phone-styles;
  }

  &__one-option-number-wrap {
    @extend .common-selected-phone-styles;
  }

  &__selected-number-wrap:hover {
    @extend .active;
    fill: var(--primary-color);
  }

  &__selected-country-wrap {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    justify-content: flex-start;
    border-radius: 6px;
    background-color: $white-gray;
    font-size: 14px;
    line-height: 1.29;
    cursor: pointer;
    span {
      display: inline-block; 
      white-space: pre-wrap; 
      word-break: break-word;
    }
  }

  &__icon:hover {
  @extend .active
  }

  &__icon-active {
    transform: rotate(150deg);
    margin-left: 4px;
    @extend .active
  }

  &__options-wrapper {
    background: $white;
    max-height: 200px;
    width: 280px;
    position: absolute;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    top: 0px;
    z-index: 2;
    text-align: left;
    word-break: break-all;
    overflow: auto;
    scrollbar-width: thin;
  }

  &__option {
    text-align: left;
    width: 100%;
    text-wrap: nowrap;
    line-height: 32px;
    padding: 0 10px;
    border-top: solid 1px $mid-gray;
    font-size: 14px;
  }

  &__option:hover {
    background: $cool-gray;
    cursor: pointer;
  }

  &__option:active {
    outline: none;
  }

  &__selected-first-child {
    width: 100%;
  }

  &__selected-first-child:active {
    outline: none;
  }

  &__option:first-child {
    display: flex;
    align-items: center;
    border-top: none;
  }
}

.active {
  font-size: 20px;
  cursor: pointer;
  color: var(--primary-color);
}

.common-selected-phone-styles {
  font-size: 20px;
  text-decoration: none;
}
