.organization-list-with-details {
  margin: 40px 0 58px 0;
  padding-right: 20px;
  padding-left: 0;

  button.toggle {
    margin: 10px 0;
    display: flex;
    align-items: center;
    outline: none;

    span:nth-of-type(2) {
      transform: rotate(90deg);
      margin-left: 10px;
    }

    &.expanded {
      span:nth-of-type(2) {
        transform: rotate(-90deg);
        margin-left: 5px;
      }
    }
  }

  h2 {
    font-weight: 400;
    margin-top: 30px;
    line-height: 1;
  }
}