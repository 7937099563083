.meeting-details-links {
  margin: 40px 0 58px 0;
  padding-right: 20px;
  padding-left: 0;

  button.toggle {
    margin: 10px 0;
    display: flex;
    align-items: center;
    outline: none;

    span:nth-of-type(2) {
      transform: rotate(90deg);
      margin-left: 10px;
    }

  }

  &__expanded {
    button.toggle {
      span:nth-of-type(2) {
        transform: rotate(-90deg);
        margin-left: 5px;
      }
    }
  }

  &__downloads-section {
    padding-left: 30px;
  }

  &__mapper {
    max-width: 400px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px 10px;

    .link {
      max-width: 370px;
      width: fit-content;

    }
  }

  h2 {
    font-weight: 400;
    margin-top: 30px;
    line-height: 1;
  }
}