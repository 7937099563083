.speaker {
  padding: 15px 0;
  display: flex;

  $this: &;

  &__details {
    width: calc(100% - 131px);
    margin-right: 26px;

    h3 {
      margin: 0 0 5px 0;
      font-size: 16px;
      letter-spacing: 0.05px;
      word-break: break-word;
    }

    #{$this}__title {
      margin: 0 0 5px 0;
      font-size: 13px;
      line-height: 1.38;
    }

    #{$this}__organization {
      margin: 0 0 10px 0;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.38;
      word-break: break-all;
    }

    #{$this}__bio {
      button {
        display: flex;
        align-items: center;
        outline: none;

        span {
          color: var(--primary-color);

          &:nth-of-type(1) {
            font-size: 13px;
            line-height: 1.38;
            margin-right: 8px;
          }

          &:nth-of-type(2) {
            font-size: 24px;
            line-height: 18px;
            transform: rotate(90deg);
          }
        }
      }

      p {
        margin: 5px 0 0 0;
        font-size: 12px;
        line-height: 1.25;
        overflow-wrap: break-word;
      }

      &_expanded {
        button {
          span {
            &:nth-of-type(1) {
              margin-right: 3px;
            }

            &:nth-of-type(2) {
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }

  &__photo {
    width: 105px;

    img {
      width: 105px;
      height: 140px;
      object-fit: cover;
      transform: translate3d(0px, 0px, 0.1px);
    }
  }
}