.attendee {
  padding: 15px 0;
  display: flex;

  $this: &;

  &__details {
    width: calc(100% - 125px);
    margin-right: 20px;

    h3 {
      margin: 0 0 5px 0;
    }

    #{$this}__title {
      margin: 0 0 5px 0;
    }

    #{$this}__organization {
      margin: 0 0 10px 0;
    }

    #{$this}__bio {
      button.toggle {
        margin: 5px 0 0 0;

        span {
          color: var(--primary-color);
        }

        .toggle-text {
          font-size: 13px;
          font-weight: 600;
        }

        .toggle-arrow {
          font-size: 24px;
          line-height: 18px;
        }
      }

      p {
        overflow-wrap: break-word;
        max-height: 60px;
        overflow: hidden;
      }

      &_expanded {
        p {
          max-height: unset;
        }
      }
    }
  }

  &__photo {
    width: 105px;

    img {
      width: 105px;
      height: 140px;
      object-fit: cover;
    }
  }
}