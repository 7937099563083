@import '../../../styles/palette.scss';

.section-label {
  font-size: 30px;
  font-weight: 500;
  color: var(--primary-color);
}

.replays-anchor {
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  color: var(--primary-color);
  text-decoration: none !important;
  border-radius: 5px;
  background: #f4f4f4;

  &:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  }
}

.meeting-card {
  margin: 16px 15px 15px;
  width: 246px;
  height: 332px;
  word-break: break-all;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 16px;

  &__img {
    width: 100%;

    img {
      width: 100%;
      height: 110px;
      object-fit: cover;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;

    &-date {
      align-items: center;
      font-size: 12px;
      line-height: 22px;
    }

    &-day {
      line-height: 22px;
      vertical-align: middle;
      color: var(--primary-color);
      font-weight: 600;
    }

    &-topic {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      word-break: break-word;
    }

    &-inner {
      padding: 0px 15px 15px 15px;
      width: 100%;

      &-more-button {
        padding-bottom: 10px;
      }
    }

    &-footer-buttons {
      width: 100%;
    }

    &-more-button {
      margin: 0 !important;
    }

  }

  &__organizations-list {
    margin-top: 22px;
  }

  &__organizations-list-item {
    font-size: 12px;
    line-height: 22px;
  }

  &__image--empty {

    .meeting-card__content {
      height: 100%;
    }
    &:not(.meeting-card--upcoming) .meeting-card__content-inner {
      margin-bottom: 0px;
    }
  }

  &__expandable-content {
    overflow: hidden;
    overflow-wrap: break-word;
    max-height: 220px;
  }

  &__watch-btn {
    padding: 9px 30px;
    font-size: 11px;
    color: white;
    border-radius: 5px;
    background: var(--primary-color);
  }

  &--expanded {
    height: auto;

    .toggle {
      &-arrow {
        transform: rotate(270deg);
      }
    }

    .meeting-card__expandable-content {
      height: 100%;
      max-height: none;
    }
  }

  &--upcoming {
    height: 376px;
  }

  &__links-mapper {
    max-width: 216px;
    gap: 0 10px;
    margin-top: 22px;

    & > div {
      margin-bottom: 22px;

      &:last-child {
        margin-bottom: 0;
      }

      .link {
        max-width: 216px;
        height: 22px;
      }
    }
  }

  .notes {
    font-size: 12px;
    line-height: 22px;
    margin-top: 22px;
  }
}

.featured-card {
  margin: 15px;
  width: 100%;
  height: 288px;
  word-break: break-all;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
  display: flex;
  flex-direction: row-reverse;

  &__img {
    width: 40%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    img {
      max-width: 100%;
      max-height: 270px;
      object-fit: cover;
    }
  }

  &__content {
    width: 60%;
    padding: 25px 30px 30px 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;

    &-date {
      line-height: 22px;
      font-weight: 600;
      font-size: 12px;
      vertical-align: middle;
      color: var(--primary-color);
    }

    &-topic {
      font-size: 20px;
      line-height: 22px;
      word-break: break-word;
    }
  }

  &__organizations-list {
    margin-top: 22px;
  }

  &__organizations-list-item {
    font-size: 12px;
    line-height: 22px;
  }

  &__image--empty {

    .featured-card__content {
      height: 100%;
    }
  }

  &__expandable-content {
    overflow: hidden;
    overflow-wrap: break-word;
    max-height: 154px;
  }

  &__watch-btn {
    padding: 9px 30px;
    font-size: 11px;
    font-weight: 500;
    color: white;
    border-radius: 5px;
    background: var(--primary-color);
  }

  &--expanded {
    height: auto;

    .featured-card__content {
      height: 100%;
    }

    .toggle {
      &-arrow {
        transform: rotate(270deg);
      }
    }

    .featured-card__expandable-content {
      height: 100%;
      max-height: none;
    }
  }

  &__links-mapper {
    max-width: 378px;
    gap: 0 10px;
    margin-top: 22px;

    & > div {
      max-width: 378px;
      margin-bottom: 22px;

      &:last-child {
        margin-bottom: 0;
      }

      .link {
        height: 22px;
      }
    }
  }

  .notes {
    font-size: 12px;
    line-height: 22px;
    margin-top: 22px;
  }
}

.toggle {
  margin-bottom: 10px;
  padding: 3px 10px;
  border-radius: 5px;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: var(--primary-color);
  }

  &-text {
    font-weight: 600;
    font-size: 11px;
    margin-right: 5px;
  }

  &-arrow {
    display: block;
    width: 11px;
    font-size: 24px;
    line-height: 18px;
    transform: rotate(90deg);
  }
}

.replays-carousel-wrapper {
  .alice-carousel__wrapper {
    width: calc(100% + 20px);
    margin-left: -15px;
  }
  .alice-carousel__stage-item {
    margin: 0 2px;
    width: 5px;
  }
  .alice-carousel__next-btn {
    right: -60px;
  }
}

.start-time {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
  line-height: 22px;

  &.ml-0 {
    margin-left: 0;
  }
}

.meeting-card-grouped-track {
  margin-top: 30px;
  &__title-block {
    margin: 30px 0 30px 15px;
  }
  &__title {
    font-size: 16px;
    line-height: 18px;
    color: #1a1a1a;
    font-weight: 600;
  }
  &__count-title {
    font-size: 12px;
    line-height: 18px;
    color: #1a1a1a;
  }
  &__card-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

.featured-card-buttons-wrapper {
  width: 100%;
  margin-top: 10px;
}

.meeting-card-grouped-track-wrapper {
  &__line-block {
    position: relative;
    height: 1px;
    margin-top: 15px;
  }
  &__line {
    position: absolute;
    left: -32px;
    border-bottom: 1px solid $border-gray;
    width: calc(110% - 20px);
    @media (max-width: 1082px) {
      width: 110%;
    }
    @media (max-width: 767px) {
      width: 120%;
    }
  }

}

section.replays-search-result {
  margin: 30px 20px;
  width: auto;
  min-height: auto;
  overflow-y: auto;
  font-family: "Montserrat";
  font-size: 15px;

  pre {
    display: inline;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 500;
  }
}

.coming-soon {
  margin-left: 15px;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 12px;
  color: $moon-gray;
}