.session-choices {
  &__label {
    margin-top: 35px;
    margin-bottom: 9px;
  }

  .date-filter {
    width: calc(100% - 200px);

    &__container {
      padding-left: 5px;
    }

    &__option {
      margin-right: 10px;
      background-color: #f4f5f6;
      color: var(--primary-color);

      &:hover:not(.date-filter__option--active) {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
      }

      &--active {
        color: white !important;
        background: var(--primary-color);
      }
    }

    &__more:not(.date-filter__option--active) {
      background: transparent;
    }
  }

  &__selected-day-label {
    margin-bottom: 20px;
  }

  &__meeting {
    border-top: 1px solid #b2bfc5;
    display: flex;
  }

  &__meeting-time {
    font-size: 12px;
    width: 115px;
    margin: 12px;
  }

  &__meeting-topic {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
    color: #252c37;
  }

  &__meetings-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f4f5f6;
    border-top: 1px solid #b2bfc5;
    border-bottom: 1px solid #b2bfc5;
    font-size: 11px;
    height: 50px;
  }

  &__current-day {
    font-weight: 600;
  }

  &__day-button {
    font-size: 11px;
    font-weight: 500;
    padding: 6px;
    margin: 0 10px;

    &:disabled {
      opacity: 0.4;
    }

    &:disabled:after, &:disabled:hover:after {
      background: inherit;
    }
  }

  &__meeting_selection {
    margin-left: 10px;
  }
}
