.editable-session {
  &--table-row-right {
    flex-direction: column
  }

  .selected-date {
    color: '#053984';
  }
}

.carousel-button {
  height: 34px;
  border: none;
}
