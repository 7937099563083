@import '../../../../../src/styles/palette.scss';
.card-buttons {
  .join-button {
    max-width: 84px;
    width: 100%;
    height: 34px;
    padding: 10px 30px;
    font-size: 11px;
    font-weight: 500;
    color: white;
    border-radius: 5px;
    background: var(--primary-color);
    &:hover {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    }
  }

  .view-details-button {
    max-width: 89px;
    width: 100%;
    height: 34px;
    background: $white;
    font-size: 11px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 10px 9px 10px 9px;
    border-radius: 5px;
    border: solid 1px $border-gray;
  }

  .register-button {
    color: var(--primary-color);
    border-top: 1px solid $mid-gray;
    svg {
      fill: var(--primary-color);
    }
    &:hover {
      background: var(--primary-color) !important;
      color: $white;
      svg {
        fill: $white;
      }
    }
  }

  .registered-button {
    border-top: 1px solid $mid-gray;
  }

  .register-cancel-buttons-block {
    position: relative;
    .cancel-button {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .cancel-button {
    opacity: 0;
    background: $dark-gray !important;
    border-top: 1px solid $dark-gray;
    color: $white;
      svg {
        fill: $white;
    }
    &:hover {
      opacity: 1;

    }
  }

  &__non-featured-wrapper {
    &__top-block {
      display: flex;
      margin: 0 15px 15px 15px;
    }

    .base-register {
      max-width: 246px;
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      font-weight: 600;
      background: $white-gray;
      cursor: pointer;
      &__disabled {
        pointer-events: none;
      }
    }
    .join-button,
    .view-details-button {
      margin-right: 7px;
    }
  }

  .card-buttons__featured-wrapper {
    display: flex;
    flex-direction: row;
    gap: 15px;

    .base-register {
      max-width: 90px;
      width: 100%;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      font-weight: 600;
      background: $white;
      cursor: pointer;
      border-radius: 5px;
      border-color: transparent;
      &__disabled {
        pointer-events: none;
      }
    }

    .register-cancel-buttons-block {
      max-width: 90px;
      width: 100%;
    }

    .select-box--container {
      width: auto;
      height: 34px;
    }

    &__status {
      margin-top: 5px;
      margin-left: 8px;
    }
  }

  @media (max-width: 1082px) {
    .card-buttons__featured-wrapper {
      display: block;
    }
  }
}