@import '../../styles/palette.scss';

.select-box--container {
  cursor: pointer;
  @media (min-width: 1082px) {
    left: -105px;
  }
  @media (max-width: 1082px) {
    right: 0;
  }
}

.border-bottom {
  border-bottom: 1px solid $mid-gray;
}
