@import '../../styles/palette.scss';

  .select-box--container {
    width: 100%;
  }
  .select-box--button:not(.flex) {
    background-color: $gray-light;
  }
  .select-box {
    position: relative;
  }
  .select-box--selected-item {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 4px 12px;
    vertical-align: middle;
  }
  .select-box--items {
    width: 250px;
    position: absolute;
    background: $white;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    z-index: 2;
    text-align: left;
    &--option, button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 0;
      border-bottom: 1px solid $mid-gray;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      padding: 7px;
      padding-left: 10px;
      cursor: pointer;
      text-decoration: none !important;
      width: 100%;
      outline: none;
      background: $white;
      color: black;
    }
    button:hover {
      background: $white;
    }
    span {
      padding-left: 5px;
    }
  }
  .select-box--option {
    font-size: 12px;
    font-weight: 500;
    vertical-align: middle;
    text-decoration: none !important;
  }

  .select-box--right {
    position: absolute;
    top: -122px;
    left: 0;

    @media (max-width: 640px) { 
      position: absolute;
      top: -122px;
      left: 0;
    }
  }

.calendar-button {
  height: 34px;
  svg {
    position: relative;
    top: 4px;
  }
}