@import '../../../styles/palette.scss';

.default-page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 767px) {
    display: block;
    padding: 0 30px;
  }

  .default-page-title {
    margin: 0 0 29.5px 28px;

    &__with-search {
      width: 73%;
      max-width: 650px;
    }

    @media (max-width: 767px) {
      font-size: 35px;
      margin: 0 0 15px 0;
      width: auto;
    }
  }

  .search-wrapper {
    width: 100%;
    margin: 11px 0 28px 6px;

    @media (max-width: 767px) {
      margin: 7px 30px 28px 6px;
      max-width: 100%;
      width: auto;
    }
  }
}
