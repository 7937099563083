.error-form {
  text-align: center;
  &--icon {
    margin: 0 auto;
  }
}

.submit-message-section {
  display: none;
}

#event-public-info,
#event-details-info {
  box-sizing: border-box;
  height: fit-content;
}

#event-details-info {
  height: max-content
}
