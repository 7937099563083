@import '../../styles/palette.scss';

.preview-spinner {
  width: 15px;
  height: 15px;
  border-width: 2px;
  border-style: dashed solid solid dotted;
  border-color: $moon-gray $moon-gray transparent $moon-gray;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: content-box;
  margin: 0 7px 0 0;

  &:after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      left: 20px;
      top: 31px;
      border-right-color: $moon-gray;
      transform: rotate(-40deg);
  }
}

.outline-none {
  outline: none;
}