$blue: #258db9;
$dark-blue: #004e83;
$light-gray-blue: #99B8CD;
$gray: #cecece;
$gray-light: #f1f1f1;
$gray-darker: #999999;
$light-blue: #3b97be;
$white: #ffffff;
$black: #000000;
$error: #cc0000;
$darken-gray: #888888;

$cool-gray: #f4f5f6;
$mid-gray: #b2bfc5;
$off-black: #34464d;
$dark-off-black: #252c37;
$green-blue: #00787a;
$border-gray: #949494;
$white-gray: #f4f4f4;
$dark-gray: #5c6169;
$moon-gray: #707070;
$darker-gray: #1a1a1a;

$light-dark-shadow: rgba(0, 0, 0, 0.3);