@import '../../../styles/palette.scss';

.dial-in-info-wrapper {
  background-color: $white;
  max-width: max-content;
  margin: 10px 0px;
  border-radius: 10px;

  .dial-in-info {
    &__phone-icon-wrapper {
      margin-left: 3px;
      margin-right: 92px;
    }

    &__phone-icon {
      cursor: pointer;
      fill: $border-gray;
    }

    &__phone-icon:hover {
      fill: var(--primary-color);
    }

    &__phone-number {
      padding: 10px 12px;
      display: flex;
    }

    &__field {
      padding: 10px 15px;
      border-top: 1px solid $border-gray;
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }

    &__label {
      min-width: 100px;
      font-size: 13px;
    }

    &__value {
      font-size: 20px;
      font-weight: 400;
      word-break: break-all;
    }
  }
}
