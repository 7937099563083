.navigation-container {
  list-style: none;

  li{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a{
      display: block;
    }
  }
}