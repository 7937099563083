@import '../../../../../src/styles/palette.scss';

$spacing-small: 15px;

.general-session-card {
  &.featured-card {
    height: 372px;
    margin-left: auto;
    position: relative;

    .featured-card__img {
      position: absolute;
      top: 0;
      right: 0;
    }

    .featured-card__expandable-content {
      height: 100%;
      max-height: 250px;
    }

    .featured-card__content-footer-buttons {
      margin-top: $spacing-small;

      .toggle {
        margin-bottom: 0;
      }

      .common-btn {
        margin: 0;
        margin-inline-end: $spacing-small;
      }

      .session-meeting-title-join-btn {
        &-expanded {
          max-width: 84px;
          width: 100%;
          height: 34px;
          font-size: 11px;
          font-weight: 500;
          border-radius: 5px;
          &:hover {
            box-shadow: 0 0 6px 0 $light-dark-shadow;
          }
        }
      }
    }

    .featured-card__content {
      margin-right: auto;
    }

    .featured-card__content-inner-more-button {
      margin-bottom: $spacing-small;
    }
  }

  &.featured-card--expanded {
    height: auto;

    .featured-card__expandable-content {
      height: 100%;
      max-height: none;
    }
  }

  &.meeting-card {
    height: 374px;

    .notes {
      margin-top: 22px;

      * {
        line-height: 22px;
      }
    }

    .meeting-card__expandable-content {
      height: 100%;
      max-height: 223px;

      &.wide-height {
        max-height: 312px;
      }

    }

    &__content-footer-buttons {
      margin-top: 10px;
    }

    &.meeting-card--expanded {
      height: auto;

      .meeting-card__expandable-content {
        height: 100%;
        max-height: none;
      }
    }

    .session-meeting-title-join-btn {
      margin-left: $spacing-small;
      &-expanded {
        max-width: 84px;
        width: 100%;
        height: 34px;
        font-size: 11px;
        font-weight: 500;
        border-radius: 5px;
        &:hover {
          box-shadow: 0 0 6px 0 $light-dark-shadow;
        }
      }
    }

    .pb-0 {
      padding-bottom: 0;
    }

    .timing-block-wrapper {
      padding: 0;

      .editable-session-event-details {
        &--date {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.session-carousel-wrapper {
  .alice-carousel {
    &__stage {
      display: flex;
    }

    &__stage-item {
      display: flex;
      height: auto;
      margin-inline-end: 0px;

      .general-session-card {
        .organization-header {
          line-height: 22px;
        }
      }

      @media (min-width: 1024px) {
        width: 33.8% !important;
      }
    }

    &__next-btn,
    &__prev-btn {
      top: 40px;
    }
  }
}

.editable-session {
  &--table-row-left-date {
    font-weight: 500;
    line-height: 22px!important;
  }
}