@import '../../../../styles/palette.scss';

.connection-details-title {
  border-top: 1px solid $mid-gray;
  padding-top: 10px;
  font-size: 13px;

  button {
    display: flex;
    align-items: center;
    outline: none;

    span {
      color: var(--primary-color);

      &:nth-of-type(1) {
        font-size: 13px;
        line-height: 1.38;
        margin-right: 8px;
      }

      &:nth-of-type(2) {
        font-size: 24px;
        line-height: 18px;
        transform: rotate(90deg);
      }
    }
  }

  &_expanded {
    button {
      span {
        &:nth-of-type(2) {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.display-configuration {
  .label-block {
    padding-bottom: 7px;
  }
  .label {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #1a1a1a;
    overflow-wrap: break-word;
    padding-bottom: 7px;
  }
  .description {
    padding-bottom: 24px;
    overflow-wrap: break-word;
    font-size: 16px;
    line-height: 21px;
    color: #1a1a1a;
  }
}

.inprogress-block {
  margin-bottom: 15px;
}