@import '../../styles/palette';

.common-btn {
  cursor: pointer;
  position: relative;
  z-index: 1;

  &:disabled {
    cursor: not-allowed;
    &:hover:not(.player):after,
    &:after {
      opacity: 0.6;
    }
  }
  &:hover:not(:disabled):after,
  &:hover:not(.player):after {
    opacity: 0;
  }
  &:hover:not(.player), &:disabled {
    outline: none;

    &:after {
      content: '';
      background-color: white;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &:focus:not(:disabled) {
    outline: none;
  }

  &.without-after {
    outline: none;
    z-index: 0;

    &:hover:after {
      content: none;
    }
  }

  &.auto-height {
    min-height: 48px;
    height: auto;
  }
}

.common-btn.outline-auto-visible {
  &:focus-visible {
    outline: auto;
    outline-color: black;
    opacity: 1;
  }
}

.common-btn.outline-visible {
  &:focus {
    outline: 1px solid $mid-gray;
  }
  &:focus-visible {
    outline: auto;
    outline-color: black;
    opacity: 1;
  }
}

.common-btn.transparent-bg {
  background-color: transparent;
}
