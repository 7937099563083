@import '../../../styles/palette.scss';

.toast-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.toast {
  background-color: $white;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  .toast-container {
    width: 90%;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .toast-container {
    width: 70%;
  }
}