.editable-rich-text--wrapper {
  padding: 40px;
}

.editable-rich-text--title {
  margin-top: 0;
  margin-left: 20px;
  margin-bottom: 40px;
}

.editable-rich-text--description {
  margin-left: 20px;
  margin-top: 30px;
}