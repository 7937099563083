@import '../../../../styles/palette.scss';

.organization-navbar {
  width: 240px;
  padding: 30px;
  background-color: rgba(#005085, 0.05);

  @media (max-width: 767px) {
    display: none;
  }

  nav {
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 10px;
        a {
          text-decoration: none !important;
          margin: 14px 0;
          font-size: 13px;
          background: $cool-gray;
          font-weight: 500;
          line-height: 16px;
          display: block;
          word-break: break-word;

          &:hover {
            color: var(--primary-color);
          }

          &:nth-of-type(1) {
            margin-top: 0;
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}