.main-content-wrapper div.registration-container {
  margin-top: 8px;
}

.registration-container {
  .editable-registration-event-details {
    width: 100%;
    margin: 0 0 40px 0;
  }

  &__header {
    padding: 30px 20px;
    border-bottom: 1px solid #b2bfc5;
    margin-top: 10px;
  }
}

.registration-wrapper--pro {
  max-width: 548px;
  margin: auto;
  padding: 0 0 85px 0;

  .input-group > div {
    width: 100%;
  }

  .editable-sign-in--submit-button {
    max-width: 262px;
    margin: 50px auto 0;
  }
}
