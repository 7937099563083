.input-group {
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  & > div {
    max-width: 48.5%;
    margin-right: 0;
  }
}

.registration-error-margin {
  margin-right: -45px;
}

.registration-form {
  &__public-description {
    font-size: 14px;
    color: #252c37;
    margin: 40px 0 16px 0;
  }

  &__instructions--pro-view {
    margin: 20px 0 16px 0;
    font-size: 14px;
    color: #252c37;
  }
}