@import '../../styles/palette';

.custom-select {
  position: relative;
  &__selected-item {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: middle;
    cursor: pointer;
  }
  &__arrow:after {
    content: '>';
    font: 18px 'Consolas', monospace;
    color: #333;
    transform: rotate(90deg);
    right: 10px;
    top: 5px;
    padding: 0;
    position: absolute;
    font-weight: bold;
  }

  &__options {
    min-width: 100%;
    max-width: 400px;
    max-height: 200px;
    width: max-content;
    position: absolute;
    left: 0;
    top: 35px;
    background: $white;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    z-index: 2;
    text-align: left;
    word-break: break-all;
    overflow: auto;
    @media (max-width: 1200px) {
      max-width: 100%;
      width: 100%;
    }
  }

  &__options .option {
    line-height: 39px;
    border-top: solid 1px $mid-gray;
  }

  &__options .option:first-child {
    border-top: none;
  }

  &__options div:hover {
    background: $cool-gray;
    cursor: pointer;
  }
}