header {
  line-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    display: inline-block;
    max-height: 1.25rem;
    max-width: 150px;
    width: 100%;
    height: 100%;

    &.oe-header-logo-image {
      max-height: 1.6rem;
      width: auto;
    }
  }
}
