.editable-support--wrapper {
  padding: 40px;
}

.editable-support--title {
  margin-top: 0;
  margin-left: 20px;
  margin-bottom: 40px;
}

.editable-support--description {
  margin-left: 20px;
  margin-top: 30px;
}